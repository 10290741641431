@import "../../../../assets/_var.scss";

.commonBtn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  border-radius: 1rem;
  min-width: 14rem;
  padding: 1.425rem 2rem;
  font-weight: 600;
  background-image: $buttonGradient;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: 0.3s all ease-in-out;
  color: $white;
  text-align: center;
  border: 0.2rem solid transparent;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;


  >span {
    svg {

      path,
      polygon {
        transition: 0.3s all ease-in-out;
      }
    }
  }

  &.admin_btn {
    height: 4.5rem;
  }

  &:hover {
    background-image: $buttonGradientHover;
    color: $white;
    box-shadow: 2px 0px 12px rgba(0, 0, 0, 0.2);
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;

    //
  }

  &.outline-btn {
    border: 0.15rem solid $extraLight;
    color: $extraLight;
    background: $white;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

    &:hover {
      border-color: $secondaryColor;
      color: $secondaryColor;
      box-shadow: unset;
    }
  }

  &.outline-btn-primary {
    // border: 2px solid $baseColor;
    // color: $baseColor;
    background: $white;

    &:hover {
      // border-color: $baseColor;
      // background: $baseColor;
      color: $white;
      box-shadow: unset;
    }
  }

  &.secondary-btn {
    background: $white;
    border-color: $secondaryColor;
    color: $secondaryColor;

    // &:hover {
    //   background: transparent;
    //   border-color: $white;
    //   color: $white;
    // }
  }

  &:disabled,
  &[disabled] {
    border-color: transparent;
    background: white;
    color: $text-grey-heading ;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    box-shadow: unset;
    cursor: not-allowed;
    box-shadow: 0px 0px 4px 0px #00000040;

  }

  &.small-btn {
    border-radius: 0.4rem;
    padding: 0.4rem 1rem;
    line-height: 2.1rem;
    min-width: 11rem;
    font-weight: 500;
  }

  &.disable-btn {
    background-color: $graybg2;
    border: 1px solid $graybg2;
    // color: $black;
  }

  // for icons only

  &.icon {
    padding: 1rem;
    background-color: transparent;
    min-width: fit-content;

    span {
      margin-right: 0;

      svg {
        @media (max-width: 1679px) {
          max-width: 2rem;
        }
      }
    }
  }

  @media (max-width: 1679px) {
    font-size: 1.6rem;
    padding: 1.1rem 1rem;
  }

  @media (max-width: 991px) {
    font-size: 1.4rem;
    min-width: 10rem;
  }

  @media (max-width: 575px) {
    font-size: 1.2rem;
    min-width: 5rem;
    padding: 0.9rem 1rem;
  }
}