@import "../../../../../assets/_var.scss";

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 37px;
    height: 18px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider::before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    top: 2px;
    // bottom: 4px;
    background-color: black;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: red;
}

input:checked+.slider::before {
    transform: translateX(16px);
}