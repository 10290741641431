@import "../../../../assets/_var.scss";

.user_management_table {
  span {
    cursor: pointer;
  }

  .table tbody tr td {
    &:first-child {

      color: #7D7D7D !important;
    }
  }

}