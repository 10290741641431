@import "../../../../assets/_var.scss";

.custom-tooltip {
  &.tooltip {
    opacity: 1;

    .tooltip-inner {
      background-color: $secondaryColor;
      font-size: 1.2rem;
      max-width: 300px;
      padding: 0.5rem;
    }
    .tooltip-arrow {
      &::before {
        border-bottom-color: $secondaryColor;
      }
    }
  }
}
