@import '../../../../assets/_var.scss';

.Admin_leftside_layout {
    background-color: #6F7E8C;

    @media (max-width:991px) {
        background: #e7f6f6;
        width: 300px;
        position: fixed;
        z-index: 1;
        // display: none;
    }

    .header_logo {
        padding: 2.4rem 1rem;
        text-align: center;

        img {
            max-width: 287px;
            height: auto;
        }
    }

    .header_links {
        ul {
            overflow-y: auto;
            height: calc(100vh - 96px);

            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: rgba(0, 166, 166, 0.09);
            }

            li {
                text-align: left;
                margin-top: 2px;

                a {
                    width: 100%;
                    color: $white;
                    padding: 2.2rem 3.8rem;
                    width: 100%;
                    display: block;
                    font-size: 1.4rem;
                    font-weight: 600;
                    white-space: nowrap;

                    @media (max-width:1199px) {
                        padding: 2.2rem 2rem;
                    }

                    span {
                        margin-right: 1.4rem;

                        // img {
                        //     filter: invert(1);
                        // }

                        svg {
                            width: 26px;
                            height: 18px;

                            // transition: 0.2s all ease-in-out;
                            path {

                                // fill: $black;
                            }
                        }
                    }

                    &.active,
                    &:hover {
                        background-color: $sidebarHover;
                        // color: $white;

                        span {
                            svg {
                                path {
                                    // fill: $white;
                                }
                            }
                        }
                    }
                }
            }

            .sidebar_select {
                .selected-text {
                    background: none;
                    width: 100%;
                    padding: 1.25rem 3.8rem;
                    color: $black;
                    font-size: 1.4rem;
                    font-weight: 600;
                    transition: all 0.3s ease-in-out;

                    &.active {
                        transition: all 0.3s ease-in-out;
                    }

                }

                .global_select_options {
                    position: static;
                    overflow: unset;
                    height: auto;
                    width: 90%;
                    margin: 0 auto;
                }
            }
        }
    }


    @media (max-width:991px) {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 30rem;
        transform: translateX(-30rem);
        transition: .3s all ease-in-out;
        z-index: 999;

        &.active {
            transform: translateX(0);
            box-shadow: 0 0 14px rgba(0, 0, 0, .2);
        }
    }

    @media (max-width:991px) {
        width: 26rem;
    }
}

.toggleBtn.btn {
    padding: 0.5rem;
    // background-color: transparent;
    // background-image: $gradient;
    border-radius: 0.5rem;

    @media (min-width:1200px) {
        display: none;
    }

    @media (max-width:1199px) {
        position: fixed;
        top: 3rem;
        right: 1rem;
        z-index: 999;
        background-color: transparent;
        border: 0;

        &:focus {
            outline: none;
        }

        &:hover &:focus {
            background-color: transparent;
            border: 1px solid black;
        }

        span {
            display: block;
            width: 2.5rem;
            height: 2px;
            margin: .6rem 0;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            transition: 500ms;
            transform-origin: right center;
            transition: .3s all ease-in-out;
            background-color: $secondaryColor;


            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }


        &.active {

            span {
                // width: 2.35rem;

                &:first-child {
                    transform: rotate(-40deg);
                    position: relative;
                    left: -3px;
                }

                &:nth-child(2) {
                    transform: scale(0);
                }

                &:last-child {
                    transform: rotate(40deg);
                    position: relative;
                    left: -3px;
                }
            }
        }
    }

    @media (max-width:479px) {
        top: 5rem;
        right: 2rem;
    }
}

// .min-sidebar-AfterLg.offcanvas-lg.offcanvas-start.show {
//     width: 25rem;
//     background-color: ;
// }