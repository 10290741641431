@import "../../../../../assets/_var.scss";

.global_select {
  display: inline-block;
  width: 100%;
  text-align: center;
  position: relative;

  .selected-text {
    display: flex;
    align-items: center;
    background-color: $graybg;
    border-radius: 1rem;
    border: 0;
    padding: 1.25rem 2.2rem;
    width: 269px;
    max-width: 100%;
    color: $gray2;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: $graybg;
    }

    @media (max-width:1679px) {
      height: 6rem;
    }

    @media (max-width:767px) {
      width: 100%;
    }

    &::after {
      content: "";
      background-image: url(../../../../../assets/images/icons/green-down-arrow.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      width: 1.1rem;
      height: 0.65rem;
      position: absolute;
      right: 1.5rem;
      top: 50%;
      transform: translateY(-50%);
    }

    // &.active {
    //   &::after {

    //   }
    // }
  }

  &_options {
    z-index: 2;
    position: absolute;
    width: 269px;
    transform: translateY(0px);
    border-radius: 0.8rem;
    border: 0;
    overflow: hidden;
    box-shadow: 0px 10px 20px rgba(16, 24, 40, 0.1);
    @media (max-width:767px) {
      width: 100%;
    }

    li {
      list-style-type: none;
      padding: 1.8rem 1.6rem;
      background-color: $white;
      font-size: 1.4rem;
      text-align: start;
      border-bottom: 1px solid #ECECEC;
      cursor: pointer;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background-color: $secondaryColor;
        color: $white;
      }
    }
  }
}