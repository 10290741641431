@import "../../../../../assets/_var.scss";

.userDetail_modal {
    width: 200px;
    .modal-dialog  {
        max-width: 1000px;
        width: 95%;
    }
    .modal-title {
        margin-bottom: 3.6rem;
        font-weight: 600;
    }

    .table-responsive {
        border-bottom-left-radius: 1.6rem;
        border-bottom-right-radius: 1.6rem;
    }
    ul {
        li {
            display: flex;
            justify-content: space-between;
            // margin-bottom: 20px;
            border-bottom: 1px solid $graylight;
            padding: 2rem 5rem;

            @media(max-width: 991px) {
                padding: 1.5rem 3rem;
            }

            &:first-child {
                padding-top: 0;
            }

            p {
                margin-bottom: 0;
                font-size: 1.6rem;
                font-weight: 500;

                &:first-child {
                    color: $gray2;
                }

                span {
                    color: $secondaryColor;
                }
            }
        }
    }

    &_subsec {
        padding: 2rem 5rem 1.7rem;

        @media(max-width: 991px) {
            padding: 2rem;
        }

        h3 {
            margin-bottom: 1.2rem;
            font-size: 2rem;
            font-weight: 600;
        }

        &_block {
            display: flex;

            &_box {
                margin-right: 4.7rem;

                @media(max-width: 991px) {
                    margin-right: 2rem;
                }

                img {
                    width: 7rem;
                    height: 7rem;
                    margin-bottom: 0.6rem;
                }

                h4 {
                    font-size: 1.4rem;
                    color: $black;
                }

                p {
                    font-size: 1.1rem;
                    color: $gray2;
                }
            }
        }
    }
}