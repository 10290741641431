@import "../../../../../assets/_var.scss";

.common-modal {
.infoImageClass {
  flex-direction: column !important;
  &_main {
    margin-bottom: 15px;
    max-width: 50px;
    max-height: 50px;
  }
  &_image_main{
    max-width: 50px;
    max-height: 50px;
  }
}
  .modal-dialog {
    max-width: 550px;
    margin: 0 auto;

    @media(max-width: 479px) {
      margin: 0 1.5rem;
    }
  }

  .modal-content {
    border-radius: 1.6rem;

    // @media (max-width:767px) {
    //   padding: 2rem 2.5rem;
    // }

    .modal-header {
      border-bottom: 0;
      // padding: 0;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      padding: 3.6rem 5rem;

      .close_btn {
        background: none;
        border: none;
        border-radius: 50%;

        &:hover,
        &:focus {
          background: none;
          border: none;

        }


      }

      @media(max-width: 767px) {
        padding: 2rem 2rem;
      }

      .modal-title {
        font-size: 2.8rem;
        font-weight: 700;
        margin-bottom: 0;

        @media(max-width: 991px) {
          font-size: 2rem;
        }
      }

      span {
        svg {
          cursor: pointer;
        }
      }
    }

    // & .no-cross {
    //   display: none;
    // }

    .modal-body {
      padding: 0;
    }
  }

  &.no_btn {
    .close_btn{
      display: none;
    }
  }
}