@import "../../../../assets/_var.scss";

.top_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.8rem 5rem;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.06);
  background-color: $white;
  position: sticky;
  top: 0;
  z-index: 999;

  @media (max-width:767px) {
    display: block;
    padding: 1rem 2rem;
  }

  .heading {
    font-weight: 500;
    font-size: 2.4rem
  }

  .header_right_links {
    display: flex;
    align-items: center;

    @media (max-width:767px) {
      justify-content: space-between;
      margin-top: 1.5rem;
    }

    // @media (max-width:479px) {
    //   display: block;
    // }

    .wallet_address {
      display: flex;
      align-items: center;
      margin-right: 3.4rem;


      p {
        font-weight: 500;
        color: $gray2;

        @media (max-width:767px) {
          font-size: 1.4rem;
        }

        span {
          margin-right: 0.6rem;
        }
      }

      .balance_value {
        color: $secondaryColor;
        margin-left: 0.6rem;
      }
    }

    .header_dropdown {
      .dropdown-toggle {
        @media (max-width:767px) {
          border: 1px solid $borderclr;
          border-radius: 5rem;
          padding: 0.8rem 1rem;
          justify-content: space-between;
          width: 100%;
          margin-top: 1.5rem;
        }

        @media (max-width:575px) {
          padding: 0 1rem;
        }
      }

      .dropdown-menu {
        width: 100%;
        border-radius: 15px;
        background: #ffffff;
        padding: 0;
        margin: 0px 15px;

        &.show {
          overflow: hidden;
        }

        .dropdown-item {
          &:hover {
            background-color: #EAEDF6;
          }

          &:active {
            color: black;
          }
        }
      }
    }
  }

  .head_btn {
    display: none;
    background: transparent;
    border: none;

    @media(max-width: 991px) {
      display: block;
    }
  }

  // .toggleBtn {}


}