@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,600&display=swap');

$baseFont: "Open Sans", sans-serif;

// colors
$black: #000;
$white: #ffffff;
$baseColor: #0e0f0c;
$secondaryColor: #10767F;
$gray: #4f4f4f;
$lightGray: #6e6e73;
$lightGray2: #bbbbbb;
$blueGray: #f8fafc;
$extraLight: #d0d5dd;
$redColor: #f0263c;
$redColor2: #eb5757;
$inputborder: #D9D9D9;
$inputbg : #F0F0F0;
$gray2: #7D7D7D;
$gray3: #F6F6F6;
$gray4: #BDBDBD;
$graylight: #ECECEC;
$borderclr: #EDEDED;
$graybg: #F5F5F5;
$graybg2: #E8E8E8;
$gray5: #828282;
$F2F2F2: #f2f2f2;
// border-color
$border-color: #e0e0e0;
$border-color2: #e1e1df;
$darkgreen: #00A6A6;
$orange: #FF8A00;
$redcolor3: #EF2323;
$opacitygreen: rgba(0, 166, 166, 0.07);
$lightsky: #EDF9F9;
$grey4: #333333;
$sidebarHover: rgba(223, 227, 242, 0.2);
$baseText: #cc1e45;
$inputFocus-hover: #6F7E8C;
$bg-main: #cc1e45;
$text-grey-heading: #6F7E8C;

// gradient
$baseGradient: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
$gradient: linear-gradient(179.94deg, rgba(0, 166, 166, 0.1) 0.05%, rgba(0, 166, 166, 0.09) 50%, rgba(0, 166, 166, 0.1) 99.95%);
$sidebarGradient: linear-gradient(90deg, #F574A9 0%, #F78876 98.73%);
$buttonGradient: linear-gradient(90deg, #F574A9 0%, #F78876 98.73%);
$buttonGradientHover: linear-gradient(90deg, #F78876 0%, #F574A9 98.73%);
$bgGradient: linear-gradient(140deg, #E3E4E9 0%, #DFE3F2 98.73%);



//box shaddow
$boxShadow2: 0px 6px 16px rgba(0, 0, 0, 0.12);