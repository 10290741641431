@import "~bootstrap/scss/bootstrap";
@import "./assets/_var.scss";



.toggleBtn.btn {
  // background-image: $buttonGradient;

  &:focus,
  &:hover {
    background-color: transparent;
  }
}

html {
  font-size: 62.5%;
  background-image: $bgGradient;

  body {
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    font-family: $baseFont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: $bgGradient;
    height: 100vh;
    min-height: 100%;
    color: $baseColor;
    background-repeat: no-repeat;
  }
}

html,
body,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// *body {}

// reset css

.row {
  // margin: 0 -15px;

  >div {
    padding: 0 15px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  // transition: 0.2s all ease-in-out;
  // color: $baseText;

  // &:hover {
  //   // color: $baseColor;
  // }
}

.commonBtnsGroup {
  span {
    &:first-child {
      height: 16px;
    }
  }

  span,
  a {
    padding: 0 5px;
  }

  a {
    color: $baseText;
    text-decoration: unset;
  }
}

// img,
// svg {
//   // max-width: 100%;
// }

ul {
  padding-left: 0;
  list-style: none;
}

ul,
ol,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h2,
.h2 {
  font-size: 4.8rem;

  @media (max-width: 1679px) {
    font-size: 3.8rem;
  }

  @media (max-width: 991px) {
    font-size: 3rem;
  }

  @media (max-width: 575px) {
    font-size: 2.2rem;
  }
}

h3,
.h3 {
  font-size: 3.2rem;

  @media (max-width: 1679px) {
    font-size: 2.6rem;
  }

  @media (max-width: 991px) {
    font-size: 2.2rem;
  }

  @media (max-width: 575px) {
    font-size: 1.8rem;
  }
}

h4,
.h4 {
  font-size: 2.2rem;

  @media (max-width: 1679px) {
    font-size: 1.8rem;
  }

  @media (max-width: 991px) {
    font-size: 1.6rem;
  }

  @media (max-width: 575px) {
    font-size: 1.4rem;
  }
}

h5,
.h5 {
  font-size: 2rem;

  @media (max-width: 1679px) {
    font-size: 1.6rem;
  }

  @media (max-width: 991px) {
    font-size: 1.4rem;
  }

  @media (max-width: 575px) {
    font-size: 1.2rem;
  }
}

h6,
.h6 {
  font-size: 1.8rem;

  @media (max-width: 1679px) {
    font-size: 1.4rem;
  }

  @media (max-width: 575px) {
    font-size: 1.1rem;
  }
}

p,
.p {
  font-size: 1.6rem;

  @media (max-width: 1679px) {
    font-size: 1.4rem;
  }

  @media (max-width: 991px) {
    font-size: 1.2rem;
  }

  @media (max-width: 575px) {
    font-size: 1rem;
  }
}

small,
.small {
  font-size: 1.4rem;
}

// font weight

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer !important;
}

.green-text {
  color: $secondaryColor !important;
}

.group_form {
  position: relative;

  .eyeIcon {
    position: absolute;
    right: 22px;
    top: 40px;
    cursor: pointer;

    @media (max-width: 575px) {
      top: 34px;
    }
  }
}

.label_form {
  font-weight: 400;
  font-size: 1.8rem;
  color: #000;
  margin-bottom: 0.8rem;
}

// .form-control {
//   &:not([type="checkbox"]):not([type="radio"]) {
//     height: 5rem;
//     border-radius: 1rem;
//     background-color: $inputbg;
//     font-size: 1.4rem;
//     color: $white;
//     padding: 1.6rem 2rem;
//     border: 1px solid $inputborder;
//     color: $gray2;
//     // border: 0;
//     font-weight: 500;
//     // @extend %transition;
//     overflow: clip;

//     @media(max-width: 1279px) {
//       height: 4.4rem;
//     }

//     &::placeholder {
//       opacity: 1;
//       color: $gray2;
//     }

//     &:-moz-placeholder {
//       opacity: 1;
//       color: $inputbg;
//     }

//     &::-webkit-input-placeholder {
//       opacity: 1;
//       color: $inputbg;
//     }

//     &:focus {
//       box-shadow: none;
//       // background-color: $gray98;
//     }
//   }
// }

.page_nation {
  margin-top: 3.6rem;
  text-align: center;
}

.btn_sec {
  display: flex;
  margin-top: 3.6rem;
  margin-bottom: 4.6rem;
  justify-content: space-between;

  @media (max-width: 1199px) {
    margin: 3rem 0;
  }

  @media (max-width: 767px) {
    margin: 2rem 0;
  }

  >button {
    flex: 0 0 48%; // width: 21.5rem;
    height: 6rem;

    @media (max-width: 1199px) {
      height: 5rem;
    }

    @media (max-width: 767px) {
      height: 4rem;
    }
  }
}

// perfect scrollbar
.scrollbar-container {
  padding: 0 !important;

  .ps__thumb-y {
    background: #c8c8c8;
    border-radius: 33px;
    width: 7px !important;
    right: 4px;
  }

  .ps__rail-x {
    display: none;
  }

  .ps__rail-y {
    // position: relative;
    // right: -2px;
    width: 15px !important;
    background: $gray2 !important;
    border-radius: 29px;
    // margin-right: -3px !important;
  }
}

// .ps .ps__rail-x:hover,
// .ps .ps__rail-y:hover,
// .ps .ps__rail-x:focus,
// .ps .ps__rail-y:focus,
// .ps .ps__rail-x.ps--clicking,
// .ps .ps__rail-y.ps--clicking {
//   background: $grey2 !important;
//   border-radius: 8px;
//   width: 15px !important;
// }

.ps__thumb-x {
  background: #c8c8c8;
  height: 8px !important;
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background: #c8c8c8;
}

.ps__rail-x:hover>.ps__thumb-x,
.ps__rail-x:focus>.ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background: #c8c8c8;
  height: 3px !important;
}

textarea.create_msg {

  &:hover,
  &:focus {
    border-color: $inputFocus-hover ;
  }
}

.common_table .table thead tr th {
  background-color: whitesmoke;
}

.common_radio {
  .form-label {
    margin-right: 10px;
  }

  label {
    margin-left: 3px;
  }

  input[type="radio"] {
    margin-left: 10px;
    accent-color: #cc1e45;

  }
}

input,
.customInput:focus-visible {
  outline: unset;
}

.back-button {
  color: black;
  margin-bottom: 20px;
  display: block;

  svg {
    margin-right: 10px;
  }
}

.fluid {
  width: 100%;
}

.design-input {
  input {
    box-shadow: 6px 6px 8px 0px #D3DCE6 inset, -2px -2px 0px 0px #FFF inset;
  }
}