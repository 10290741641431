@import "../../../../../assets/_var.scss";

.common_input {
  position: relative;
  margin-bottom: 3.4rem;

  @media (max-width: 1679px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 575px) {
    margin-bottom: 1rem;
  }

  .form-label {
    font-weight: 400;
    font-size: 14px;
    color: $black;
    margin-bottom: 0.8rem;

    @media (max-width: 1679px) {
      margin-bottom: 0.4rem;
    }

    @media (max-width: 575px) {
      font-size: 12px;
    }
  }

  &_inner {
    position: relative;

    .leftIcon,
    .rightIcon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .leftIcon {
      position: absolute;
      left: 2rem;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;

      &.btn {
        cursor: pointer;
      }
    }

    .rightIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 2.6rem;
      background-color: transparent;

      &.btn {
        cursor: pointer;
      }

      @media (max-width: 1679px) {
        right: 1.2rem;

        svg {
          max-width: 2rem;
        }
      }

      @media (max-width: 1679px) {
        svg {
          max-width: 1.5rem;
        }
      }

      // &.cursor-pointer {
      //   cursor: pointer;
      //   height: calc(100% - 0.5rem);
      //   width: 7.5rem;
      //   right: 0.2rem;
      //   display: inline-flex;
      //   align-items: center;
      //   justify-content: center;
      //   border-top-right-radius: 1rem;
      //   border-bottom-right-radius: 1rem;
      // }
    }


    .design-input {
      input {
        box-shadow: 6px 6px 8px 0px #D3DCE6 inset, -2px -2px 0px 0px #FFF inset;
      }
    }

    input,
    textarea,
    .select {
      height: 6.2rem;
      width: 100%;
      border: 0.1rem solid white;
      // box-shadow: inset 0px 1px 8px rgba(0, 0, 0, 0.2);
      box-shadow: 6px 6px 8px 0px #D3DCE6 inset, -2px -2px 0px 0px #FFF inset;
      border-radius: 0.8rem;
      padding: 1.25rem 3.4rem;
      font-size: 1.6rem;
      font-weight: 600;
      color: $baseColor;
      background-color: $white;
      -webkit-transition: .18s ease-out;
      -moz-transition: .18s ease-out;
      -o-transition: .18s ease-out;
      transition: .18s ease-out;

      @media (max-width: 1679px) {
        height: 5rem;
        font-size: 1.3rem;
        padding: 1rem 2.5rem;
      }

      @media (max-width: 575px) {
        height: 4rem;
        font-size: 1.1rem;
        padding: 1rem 1.5rem;
      }

      &:hover {
        border: 1px solid #B8B6B6;
        box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.02);
      }

      &:focus {
        color: $baseColor;
        border: 1px solid #B8B6B6;
        box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);
      }

      &::placeholder {
        opacity: 0.8;
        color: #6F7E8C;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
        font-weight: 600;
      }

      &::-webkit-input-placeholder {
        color: #6F7E8C;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
      }

      &:-moz-placeholder {
        color: #6F7E8C;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $white inset;
        border: 1px solid $lightGray2;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);

        // color: $lightGray2;
      }
    }

    &.rightIconInput {
      input {
        padding-right: 7.5rem;

        @media (max-width: 1679px) {
          padding-right: 5rem;
        }

        @media (max-width: 575px) {
          padding-right: 3rem;
        }
      }
    }

    &.leftIconInput {
      input {
        padding-left: 4.75rem;
      }
    }
  }

  &.search {
    min-width: 62.5rem;
    margin-bottom: 0;

    @media (max-width: 767px) {
      margin-top: 1rem;
    }

    @media (max-width: 1199px) {
      min-width: 100%;
    }

    input {
      background: $white;

      @media (max-width: 1199px) {
        height: 4rem;
        font-size: 1.3rem;
      }
    }

    button {
      width: 5.6rem;
      height: 3.25rem;
      // background: $gray;
      border-radius: 0.8rem;
      border: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .common_input_inner {
      .rightIcon {
        right: 1rem;
      }
    }

    .error_message {
      position: absolute;
      padding: 1rem;
      width: 100%;
      text-align: center;
      background: $white;
      border-radius: 0.8rem;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }

  &.light-input {
    margin-bottom: 2rem;

    .form-label {
      margin-bottom: 1rem;
    }

    .common_input_inner {
      input {
        background: $white;
        // border: 0.1rem solid $border-clr;
      }
    }
  }

  // error message

  &--error {

    input,
    textarea,
    .select {
      border-color: $redColor2;
      color: $redColor2;

      &::placeholder {
        color: $redColor2;
      }

      &::-webkit-input-placeholder {
        color: $redColor2;
      }

      &:-moz-placeholder {
        color: $redColor2;
      }

      &:hover,
      &:focus {
        border-color: $redColor2;
      }
    }
  }
}



.customInput {
  input {
    border: 0.05rem solid var(--black-100-e-5-e-5-e-5, #e5e5e5);
    border-radius: 1rem;
    background: #f5f8fd;
    height: 5rem;
    padding: 0.5rem 2rem;

  }

  label {
    margin-left: 20px;
  }
}