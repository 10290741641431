@import "../../../../assets/_var.scss";

.user_management {
  &_table {
    .table {
      // border: 1px solid $borderclr;

      tbody {
        tr {
          td {
            color: $gray2;

            &:first-child {
              color: $black;
              font-weight: 500;
            }

            span {
              svg {
                max-width: fit-content;
              }
            }

            .custom-style {
              max-height: 30px;
              max-width: 30px;

            }
          }
        }
      }
    }
  }
}

.commonBtnsGroup {
  display: flex;
  align-items: center;
  gap: 15px;
}