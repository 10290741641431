@import '../../../../../assets/_var.scss';

.deActivate_modal {

    .modal-body {
        margin-top: 1.3rem;

        @media (max-width:767px) {
            margin-top: 2rem;
        }
    }

    .deactivate_row {
        padding: 0 5rem;
        // padding-bottom: 6.4rem;

        .user_dropdown {
            margin-bottom: 2.4rem;

            .dropdown-toggle {
                border: 1px solid #D9D9D9;
                border-radius: 1rem;
                width: 100%;
                padding: 1.8rem 2.5rem;
                justify-content: space-between;
                color: $gray2;
                font-weight: 500;

                &::after {
                    background: url("../../../../../assets/images/icons/green-down-arrow.svg");
                    background-repeat: no-repeat;
                }
            }

        }

        .common_textarea_inner {
            .form-control {
                resize: none;
                padding: 1.25rem 2.5rem;
                height: 130px;
            }
        }

        .admin_btns {
            display: flex;
            align-items: center;

            .subadminBtn {
                margin-right: 2rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .btn_sec {
        margin-top: 0;
    }
}