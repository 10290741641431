@import '../../../../assets/_var.scss';


.dropdown {
    .dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: none;
        color: $black;
        font-size: 15px;
        border: 0;
        border-radius: 10rem;
        // padding: 10px 15px;
        font-weight: bold;
        line-height: normal;
        --bs-btn-active-bg: none;
        --bs-btn-active-color: none;

        &::after {
            background: url("../../../../assets/images/icons/down_arrow_icon.svg");
            background-repeat: no-repeat;
            vertical-align: middle;
            background-size: contain;
            margin-left: 2.3rem;
            width: 12px;
            height: 9px;
            border: none;
            margin-top: 8px;
        }

        img {
            border-radius: 5rem;
            width: 4.4rem;
            height: 4.4rem;
            margin-right: 0.4rem;
        }

        &:hover {
            background-color: transparent;
            color: $black;
        }
    }

    .dropdown-menu {
        width: 100%;
        border-radius: 15px;
        background: $white;
        padding: 0;

        .dropdown-item {
            font-size: 1.4rem;
            font-weight: 400;
            padding: 1.8rem 1.6rem;
            border-bottom: 1px solid #ECECEC;
            &:last-child{
                border-bottom: 0;
            }
            &:hover {
                background-color: transparent;
                // color: $basecolor;
            }
            span{
                img{
                    margin-right: 1.1rem;
                    width: 17px;
                    height: 17px;
                }
            }
        }
    }
}