@import "../../../../../assets/_var.scss";

.create_nft_item {
  &_head {
    display: flex;
    align-items: center;

    h3 {
      font-size: 20px;
      margin-left: 1.6rem;
    }
  }

  .form-label {
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 0.8rem;
  }

  .form-control {
    margin-bottom: 2.4rem;
  }

  .item_row>div {
    padding: 0 9px;
  }

  .create_msg {
    height: 152px;

    &:focus-visible {
      outline: 0;
    }
  }

  .item_img {
    @media (max-width: 991px) {
      margin-top: 4rem;
    }

    &_box {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: 40.8rem;
      text-align: center;
      border: 1px solid #0E0F0C36;
      border-radius: 1rem;
      cursor: pointer;

      h3 {
        font-size: 2rem;
        margin-top: 2.5rem;
      }

      h4 {
        font-size: 2rem;
        margin-top: 1.2rem;

        span {
          color: $baseText;
        }
      }

      p {
        margin-top: 8.3rem;
      }

      .form-control {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100% !important;
        background-color: transparent;
        border: none;
        opacity: 0;
      }
    }

    .removeImg {
      height: 3rem;
      width: 3rem;
      color: $white;
      background-color: red;
      font-weight: bold;
      border-radius: 50%;
      border: none; // @extend %transition;
      opacity: 0;
    }

    .block {
      border-radius: 1rem;
      background-repeat: no-repeat;
      background-size: 80%;
      height: 40rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ced4da;
      padding: 20px;
      background-position: center;

      &:hover {
        backdrop-filter: blur(0.5rem);

        .removeImg {
          opacity: 1;
        }
      }
    }
  }

  .item_img_sec {
    margin-top: 2.3rem;

    .row {
      gap: 2rem;
    }

    >div {
      flex-shrink: 0;

      img {
        width: 7rem;
        height: 7rem;
        border-radius: 0.4rem;
        margin-right: 1.6rem;
        display: inline-block;
      }
    }

    .image_folder {
      display: flex;
      flex-wrap: wrap;
      gap: 1.6rem;
    }

    .cross_icon {
      position: relative;

      span {
        position: absolute;
        top: 0px;
        left: 2px;
        width: 20px;
        height: 20px;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    .plus {
      width: 7rem;
      background: $secondaryColor;
      height: 7rem;
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: 0.4rem;
      border: none;

      // &:active {
      //   background: $secondaryColor;
      // }

      input[type="file"] {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }

  .map_img {
    margin-bottom: 2rem;

    img {
      width: 100%;
    }
  }
}