@import "../../../../../assets/_var.scss";

.delete_modal {
    .modal {
        &-dialog {
            max-width: 55rem;
        }
    }

    &_content {
        padding: 0 5rem;
        text-align: center;
        margin-top: 5.4rem;

        svg {
            margin-bottom: 3.8rem;
        }

        p {
            font-size: 1.8rem;
            font-weight: 400;
        }

        .btn_sec {
            margin-bottom: 3.85rem;

            >button {
                max-width: 21.5rem;
            }
        }
    }
}